import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import Layout from "../Layout/Layout";
import SEO from "../Layout/Seo";

const PageCaseStudyTemplate = props => {
  const currentPage = props.data.wordpressPage;
  const metaTitle = currentPage.yoast.title || "";
  const metaDesc = currentPage.yoast.metadesc || "";
  const metaKeyWords = currentPage.yoast.metakeywords || "";

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "case-study"
        }}
      />
      <SEO title={metaTitle} keywords={metaKeyWords} description={metaDesc} />
      <section
        className="head"
        style={{
          backgroundImage: `url("https://wp.pandagroup.co/wp-content/uploads/2020/10/Case-Study-Header-1.jpg")`
        }}
      >
        <div className="head__overlay" />
        <h1 className="section__title--large head__title">Case Study</h1>
      </section>
      <section className="case-studies container">
        <div className="case-studies__content">
          {currentPage.acf.case_studies.map(casestudy => {
            return (
              <a href={casestudy.case_study_link} className="case-studies__single">
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img
                  className="case-studies__inner"
                  src={
                    casestudy.case_study_image &&
                    casestudy.case_study_image.localFile.childImageSharp.original.src
                  }
                  alt="Case Study Image"
                />
                <div className="case-studies__description">
                  <h3>{casestudy.case_study_title}</h3>
                  <p className="case-studies__text">{casestudy.case_study_description}</p>
                  <p>{casestudy.case_study_hashtags}</p>
                </div>
              </a>
            );
          })}
        </div>
      </section>

      <section className="main-cta services-cta">
        <div className="container">
          <div className="section__header">
            <div className="section__title section__title--contact">
              Let’s talk about your Magento project
            </div>
          </div>
          <div className="section__button">
            <div className="cta__content cta__content--what-we-can-do">
              <div className="join-us-button">
                <a href="/contact">
                  <div className="btn__cta btn__cta--green btn__cta--contactus">Contact us</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PageCaseStudyTemplate;

export const pageQuery = graphql`
  query currentCaseStudyPageMainQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      acf {
        case_studies {
          case_study_image {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          case_study_title
          case_study_description
          case_study_hashtags
          case_study_link
        }
      }
      link
      yoast {
        title
        metakeywords
        metadesc
      }
      date(formatString: "MMMM DD, YYYY")
    }
    allWordpressWpCaseStudy {
      nodes {
        id
        title
        link
        path
        wordpress_id
      }
    }
  }
`;
